import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocation, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
    /*faYoutube,
    faFacebook,
    faLinkedin,
    faInstagram,*/
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Contact = () => {

    function handleSubmit(e) {
        e.preventDefault();

        emailjs.sendForm('service_u07prvd', 'template_3r6fo9t', e.target, '4tarkWwFT1FS6sm7H')
            .then((result) => {
                console.log(result.text);
                alert("Message Sent !!");
            }, (error) => {
                console.log(error.text);
                alert("oops something went wrong !!");
            });
        e.target.reset();
    }

    return (
        <section id="contact">
            <div className="container">

                <div className="inner-contact-container">

                    <div className="contact-heading">
                        <h1>SOCIAL</h1>
                        <div className="social-container1">
                            <a href="https://x.com/Legacy_Labz_io/status/1758762810547327352?s=20" target="_blank" rel="noopener noreferrer"
                                className="Twitter social" style={{color: 'white'}}>
                                <FontAwesomeIcon icon={faTwitter} size="2x" />
                            </a>
                        </div>
                    </div>

                    <div className="left-container">
                        <div className="contact-heading">
                            <h2>CONTACT US</h2>
                        </div>
                        <div className="contact-text">
                            <p>
                                Do you have an idea and would like to come in to discuss your
                                game project requirements? Just hurry up and contact us. Submit
                                the form and let’s check how we can cooperate.
                            </p>
                        </div>
                        <div className='contact-lowerleft-container'>

                            <div className='left-text'>
                                <h2>UAE Office Address</h2>
                                <FontAwesomeIcon
                                    icon={faLocation}
                                    style={{ color: 'white', marginRight: '10' }}
                                />
                                <span><a href='https://duolabz.ae/' target="_blank" rel="noopener noreferrer">Development hub: Floor M1, Office Number 8</a></span>
                            </div>
                            <div className='left-text'>
                                <FontAwesomeIcon
                                    icon={faLocation}
                                    style={{ color: 'white', marginRight: '10' }}
                                />
                                <span>Management hub: UAE</span>
                            </div>
                            <div className='left-text'>
                                <FontAwesomeIcon
                                    icon={faPhone}
                                    style={{ color: 'white', marginRight: '10' }}
                                />
                                <span>Whatsapp: <a href="https://wa.me/+971555911100" target="_blank" rel="noreferrer">+971555911100</a></span>
                            </div>

                            <div className='left-text'>
                                <h2>Pakistan Office Address</h2>
                                <FontAwesomeIcon
                                    icon={faLocation}
                                    style={{ color: 'white', marginRight: '10' }}
                                />
                                <span><a href='https://duolabz.com/' target="_blank" rel="noopener noreferrer">Development hub: 536-F Johar Town Lahore, Pakistan</a></span>
                            </div>
                            <div className='left-text'>
                                <FontAwesomeIcon
                                    icon={faLocation}
                                    style={{ color: 'white', marginRight: '10' }}
                                />
                                <span>Management hub: Pakistan</span>
                            </div>
                            <div className='left-text'>
                                <FontAwesomeIcon
                                    icon={faPhone}
                                    style={{ color: 'white', marginRight: '10' }}
                                />
                                <span>Phone: <a href="tel:+923330444470">+923330444470</a></span>
                            </div>
                            {/*
                            <div className='left-text'>
                                    <FontAwesomeIcon
                                                icon={faEnvelope}
                                                style={{color:'white', marginRight: '10'}}
                                    />
                                    <a href='mailto:support@duolabz.com'>support@duolabz.com</a>
                            </div>
                            */}
                            <div className='left-text'>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: 'white', marginRight: '10' }}
                                />
                                <a href='mailto:contact@legacylabz.io'>To apply for a job please contact contact@legacylabz.io</a>
                            </div>

                            <div className="social-container">
                                {/*
                                <a href="https://www.youtube.com/channel/UCkRKJn5Gl44kz19p1-qYtWg" target="_blank" rel="noopener noreferrer"
                                    className="youtube social">
                                    <FontAwesomeIcon icon={faYoutube} size="2x" />
                                </a>
                                <a href="https://www.facebook.com/Duolabz.ae" target="_blank" rel="noopener noreferrer"
                                    className="facebook social">
                                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                                </a>
                                <a href="https://www.linkedin.com/company/duolabzae/" target="_blank" rel="noopener noreferrer" className="linkedin social">
                                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                </a>
                                <a href="https://www.instagram.com/duolabz.ae/" target="_blank" rel="noopener noreferrer"
                                    className="instagram social">
                                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                                </a>
                                */}
                                <a href="https://x.com/Legacy_Labz_io/status/1758762810547327352?s=20" target="_blank" rel="noopener noreferrer"
                                    className="Twitter social">
                                    <FontAwesomeIcon icon={faTwitter} size="2x" />
                                </a>
                            </div>

                            {/*
                            <div className='qr-code'>
                                <img src={QRcode} alt='QR code'/>
                            </div>
                            */}
                        </div>
                    </div>
                    <div className="right-container">
                        <form
                            action=""
                            onSubmit={handleSubmit}
                            /*action="contact.php"*/
                            method="POST"
                            target="_blank"
                            className="contactForm"

                        >

                            <div className="mb-3 pt-0">
                                <input id="input-field"
                                    type="text"
                                    placeholder="Your name"
                                    name="name"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                                    required
                                />
                            </div>
                            <div className="mb-3 pt-0">
                                <input id="input-field"
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                                    required
                                />
                            </div>
                            <div className="mb-3 pt-0">
                                <input id="input-field"
                                    type="text"
                                    placeholder="Subject"
                                    name="subject"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                                    required
                                />
                            </div>
                            <div className="mb-3 pt-0">
                                <textarea id="text-field"
                                    placeholder="Your message"
                                    name="message"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                                    required
                                />
                            </div>
                            <div className="mb-3 pt-0">
                                <button id="contact-btn"
                                    className="submit-btn bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                >
                                    SUBMIT
                                </button>


                                {/*<div className='row'>{result ? <Result/>: null}</div> */}

                            </div>
                        </form>
                    </div>
                    <div style={{ clear: "both" }}></div>

                </div>

            </div>
        </section>
    );
}

export default Contact;