import img1 from '../assets/team1.png';
import img2 from '../assets/team2.png';
/*import img3 from '../assets/team3.png';*/

const Data = [
    {
        photo: img1, title: "Founder", description: "Mando is a father and husband. He is a professional artist with more than 20 years of activity, developing his activity in all the countries of the European continent, China, Japan, Chile and Argentina.Mando is a big fan and passionate about the entire crypto and web3 world, actively participating since 2021: he is founder of the NFT project The Samurai Legacy and also founder of Legacy Labz.",
    },
    {
        photo: img2, title: "Co-Founder", description: "Innovator and entrepreneur with more than 12 years in business leadership. Experienced in all aspects of business formation, operation, finance, and management. Product developer with education in research & development. Communicator and motivator who identifies and leverages assets in teammates to reach goals. Relentless optimist who believes there is no failure, only feedback.",
    },
    /*
    {
        photo: img3, title: "job desccription", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },*/
];

const Team = () => {
    return (
        <section id="team">
            <div className="container">
                <div className="team-container">
                    <div className="team-heading">
                        <h1>TEAM</h1>
                    </div>

                    <div className="teamimage-container">
                        {Data.map((item, index) => (
                            <div key={index} className="inline-div">
                                <img src={item.photo} alt="member" />
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Team;