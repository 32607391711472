
import React, { useState } from 'react';

const Services = () => {

    const tabs = [
        {
            id: 1,
            tabTitle: 'Services',
            content: `Hire Blockchain Developer
            Hire ReactJs Developers
            Hire React Native Developer
            Hire Mobile Application Developers
            Hire PHP Experts
            Hire Magento Developers
            Hire Prestashop Developer
            Hire Digital Marketing Experts
            E-commerce
            Mobile Apps`
        },
        {
            id: 2,
            tabTitle: 'Crypto Development',
            content: `Crypto Development
            Cryptocurrency Development
            Cryptocurrency Coin Development
            Cryptocurrency Wallet Development
            Crypto Payment Gateway
            IDO Development
            IDO Marketing Services
            Binance Smart Chain
            Initial Insurance Offering
            STO Development
            Sto Marketing
            Token Development Company
            Create your own Token
            Chatbot Development
            P2P Lending Software Development
            Tron Dapps Development
            Meme Coin Development`
        },
        {
            id: 3,
            tabTitle: 'Blockchain',
            content: `Blockchain
            Tracefood
            E-commerce
            Supply Chain
            Real Estate
            Health Care
            Transportation
            Education Blockchain
            Social Media Blockchain
            Smart Contract Audit`
        },
        {
            id: 4,
            tabTitle: 'Metaverse',
            content: `Metaverse development services
            Metaverse application development
            Metaverse event platform development
            Metaverse avatar development
            Metaverse token development
            Metaverse Education Platform Development
            Metaverse For Jewelry Store
            Metaverse Virtual Office Development
            VR Development
            VR GAME Development
            Metaverse game development company
            Metaverse casino games development
            Metaverse real estate development
            Metaverse banking development
            Metaverse fashion development company
            Metaverse ecommerce development
            Metaverse integration services
            Metaverse healthcare development
            Metaverse insurance platform development
            VR for Industries
            VR For Mining
            VR For Logistics
            VR For Automotive`
        },
        {
            id: 5,
            tabTitle: 'NFT',
            content: `NFT Development Services
            NFT Exchange Platform Development
            NFT Token Development
            NFT P2P Exchange Development
            NFT Lending Platform Development
            NFT Loan Platform Development
            NFT Gaming Platform Development
            NFT Studio
            NFT With Ethereum Layer 2
            NFT For Physical Assets
            White Label NFT Multichain Development
            NFT game development like evolution land
            NFT Marketplace Development
            OpenSea Clone Script
            NFT Art Marketplace
            Nft Marketplace For Mekaverse
            NFT Marketplace in Polygon
            NFT Marketplace On Harmony
            NFT Marketplace Development For Domains
            NFT Marketplace on Sidechain
            Cross Chain NFT Marketplace Development
            Celebrity NFT Marketplace Development
            NFT Marketing Services
            Facebook NFT Ads
            NFT Influencer Marketing Agency
            NFT Telegram Marketing
            NFT Social Media Marketing Services
            Instagram NFT Ads
            NFT Discord Marketing Services
            NFT Sports Platform Development
            NFT Auction Portal
            NFT Art Tokenization
            NFT For Real Estate
            NFT For Music
            NFT Launchpad Development Services
            Shards NFT Platform Development
            NFT in Ecommerce
            NFT Metaverse Launchpad Solutions
            NFT Platform Like Bored Ape Yacht Club
            Smart Contract  Based NFT Development
            Play To Earn NFT Game Development
            Rarible Clone Script
            Sandbox Clone Script
            Axie Infinity Clone Script
            Polkacity Clone Script
            Nifty Gateway Clone Script
            Gods Unchained Clone Script
            Terra Virtua Clone Script
            SuperRare Clone Script
            Sorare Clone Script
            Zed Run Clone Script
            NBA Top Shot Clone Script`
        },
        {
            id: 6,
            tabTitle: 'Defi',
            content: `Decentralized Finance DeFi Development
            Uniswap Clone Script
            DeFi Token Development
            DeFi Lending and Borrowing
            DeFi Smart Contract Development Services
            Defi Staking Platform Development
            DeFi Yield Farming Development
            DeFi Crowdfunding Platform
            DeFi ICO Development Services
            DeFi Wallet Development Solutions
            DeFi Solutions For E-commerce Marketplace
            DeFi Dapp Development Software
            DeFi Marketing Services
            DeFi Real Estate Platform
            DeFi Exchange Development Solutions
            Pancakeswap Clone Script
            Decentralized Finance Protocol Like Yearn`
        },

    ];

    const [currentTab, setCurrentTab] = useState(tabs[0].id);

    const handleTabClick = (id) => {
        setCurrentTab(id);
    }

    return (
        <section id="sevices-container">
            <div className="container">
                <div className="about-inner-div">
                    <div className="services-heading">
                        <h1 style={{ color: '#ffffff' }}>SERVICES</h1>
                    </div>

                    <div className='tabs'>
                        {tabs.map((tab) =>
                            <button key={tab.id} disabled={currentTab === tab.id} onClick={() => handleTabClick(tab.id)}>{tab.tabTitle}</button>
                        )}
                    </div>

                    {/*
                    <div className='content'>
                        {tabs.map((tab) =>
                            <div key={tab.id} style={{ display: currentTab === tab.id ? 'grid' : 'none', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                                {tab.content.split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}
                            </div>
                        )}
                    </div>
                                */}

                    <div className='content'>
                        {tabs.map((tab) => (
                            <div key={tab.id} style={{ display: currentTab === tab.id ? 'block' : 'none' }}>
                                {tab.content.split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}
                            </div>
                        ))}
                    </div>

                    {/*
                    <div className='content'>
                        {tabs.map((tab) =>
                            <div key={tab.id} style={{ display: currentTab === tab.id ? 'grid' : 'none', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                                {tab.content.split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}
                            </div>
                        )}
                    </div>
                    */}
                </div>
            </div>
        </section>
    );

    /*
    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }

    return (
        <section id="sevices-container">

            <div className="container">
                <div className="about-inner-div">
                    <div className="services-heading">
                        <h1 style={{ color: '#ffffff' }}>SERVICES</h1>
                    </div>



                    <div className='tabs'>
                        {tabs.map((tab, i) =>
                            <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.tabTitle}</button>
                        )}
                    </div>
                    <div className='content'>
                        {tabs.map((tab, i) =>
                            <div key={i}>
                            {currentTab === `${tab.id}` && 
                                <div>
                                    {tab.content.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </div>
                            }
                        </div>

                        )}
                    </div>



                </div>
            </div>
        </section>
    );
    */

}

export default Services;