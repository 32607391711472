import React from "react";
import Slider from "react-slick";

import image1 from '../assets/project1.png';
import image2 from '../assets/project2.png';
import image3 from '../assets/project3.png';

const Data = [
    {
        photo: image1,
    },
    {
        photo: image2,
    },
    {
        photo: image3,
    },
];

const Projects = () => {
    const settings = {
        className: "center",
        centerPadding: "60px",
        dots: false,
        centerMode: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                }
            }
        ]
    }
    return (
        <section id="projects">
            <div className="container">
                <div className="projects-container">
                    <div className="projects-heading">
                        <h1>PROJECTS</h1>
                    </div>
                    <div className="slider-container">
                        <Slider style={{ width: "100%" }} className="sldier-carousel" {...settings}>

                            {Data.map((item, index) => (
                                <div key={index} className="slider-image">
                                    <img src={item.photo} alt="logo" />
                                </div>
                            ))}

                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects;