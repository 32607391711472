import Navigation from "./Navigation";


const Header=()=>{
    const handleClick = (e, sectionId) => {
        e.preventDefault(); // Prevent the default anchor tag behavior
        const section = document.getElementById(sectionId);
        if (section) {
          window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
            offset: -70,
          });
        }
      };
    return(
        <section id="header">
            <Navigation />
            <div className="container">
                <div className="inner-container">
                    <div className="left-container">
                        <div className="header-heading">
                            <h1>
                                TECH <br />
                                EVOLUTION <br />
                                LEADERS
                            </h1>
                        </div>
                        <div className="header-text">
                            <p>
                                Transforming Tommorow's Tech
                            </p>
                        </div>
                        <div className="header-btn">
                            <a href="#sevices-container" target="_blank" onClick={(e) => handleClick(e, 'sevices-container')}><h2>LEARN MORE</h2></a>
                        </div>
                    </div>
                    <div className="right-container">
                        <div className="right-image">
                        </div>
                    </div>
                    <div style={{clear: "both"}}></div>
                </div>
            </div>
        </section>
    );
}

export default Header;