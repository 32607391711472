import image1 from "../assets/image.png";

const About = () => {
    return (
        <section id="about">
            <div className="container">
                <div className="inner-container">
                    <div className="left-about">
                        <div className="left-container-image">
                            <img src={image1} alt='nftimage' />
                        </div>
                    </div>
                    <div className="right-about">
                        <div className="right-container-text">
                            <div className="right-container-text-heading">
                                <h2>WHO WE ARE</h2>
                            </div>
                            <div className="right-container-text-text">
                                <p>
                                    Legacy labz is a platform for visions and thoughts.
                                    A flawless performance and an amazing online
                                    platform to back that performance are the
                                    necessities of today's world. We are here to
                                    create that platform for you. We gather your
                                    concepts even if they are scattered and covert
                                    them into actual form. Elevating your vision is
                                    our mission. We are a professionally trained
                                    strong team of Designers, managers,
                                    developers, advisors and HRs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            </div>
        </section>
    );
}

export default About;