import './App.css';
import './Responsiveness.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Header from './components/Header';
import About from './components/About';
import Contact from './components/ContactUs';
import Projects from './components/Projects';
import Team from './components/Team';
import Services from './components/Services';

function App() {
  return (
    <div className="body">
      <Header />
      <div className='body-bg'>
        <About />
        <Projects />
        <Services />
        <Team />
        <Contact />
      </div>
    </div>
  );
}

export default App;
